import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentModule } from '../../../shared/component/component.module';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from '../../../shared/directive/directive.module';



@NgModule({
  declarations: [
    RegisterComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ComponentModule,
    DirectiveModule,
    RouterModule
  ],
  exports: [
    RegisterComponent
  ]
})
export class RegisterModule { }
