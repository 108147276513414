import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterWithWellbetComponent } from './register-with-wellbet.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentModule } from '../../../shared/component/component.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    RegisterWithWellbetComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ComponentModule,
    RouterModule
  ]
})
export class RegisterWithWellbetModule { }
