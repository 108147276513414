@if(shouldShowTerms) {

    <div class="terms-wrapper">
        <div class="terms-sticky-header">
            <div class="terms-header-wrapper">
                <a class="terms-back-btn" (click)="onToggleShowTerms()">
                    <span class="material-symbols-outlined">arrow_back_ios</span>
                </a>
                <span class="title text-center">{{ langx['TERMS_TITLE'] }}</span>
            </div>
        </div>

        <div class="terms-body">
            <app-dynamic-template [helpCenterItems]="helpCenterItems"></app-dynamic-template>
            <div class="bottom-content"></div>
        </div>

        <div class="terms-footer">
            <a class="btn btn-primary terms-accept-btn" (click)="onAcceptTerms()">{{ langx['TERMS_ACCEPT_TEXT'] }}</a>
        </div>
    </div>

} @else {

    <div class="register-wrapper">
        <div class="register-form-wrapper">
            <h1 class="register-form-title text header text-center">{{ langx['REGISTER_TITLE'] }}</h1>
            <p class="register-form-description text-small text-center">{{ langx['REGISTER_DESCRIPTION'] }}</p>

            <form [formGroup]="registrationForm" class="register-form register-flex-container text-small" (ngSubmit)="onRegisterSubmitButtonClick()">
                <div class="form-group register-flex-item">
                    <input
                        type="text"
                        class="form-control register-field-item"
                        placeholder="{{ langx['REGISTER_LOGIN_NAME_PLACEHOLDER'] }}"
                        formControlName="loginName"
                        (focus)="onLoginNameFocus()"
                        (blur)="onLoginNameBlur()"
                        #loginNameInputField
                    />
                    <app-clear-input-icon [targetInputField]="loginNameInputField" (clearInput)="onClearInput()"></app-clear-input-icon>

                    @if(shouldShowLoginNameInfo){
                        <p class="register-field-info text-smaller"><span class="material-symbols-outlined">info</span> {{ langx['REGISTER_LOGINNAME_FORMAT_INVALID'] }}</p>
                    } @else if (isFieldEmpty('loginName')) {
                        <p class="register-field-error text-smaller"><span class="material-symbols-outlined">error</span> {{ langx['REGISTER_LOGINNAME_EMPTY_FIELD'] }}</p>
                    } @else if (isFieldInvalid('loginName')) {
                        <p class="register-field-error text-smaller"><span class="material-symbols-outlined">error</span> {{ langx['REGISTER_LOGINNAME_FORMAT_INVALID'] }}</p>
                    } @else if(registrationFormControl['loginName'].value){
                        @if(!isLoginNameValid) {
                            <p class="register-field-error text-smaller"><span class="material-symbols-outlined">error</span> {{ loginNameErrorMsg }}</p>
                        }
                    }
    
                </div>
    
                <div class="form-group register-flex-item">
                    <input
                        [type]="shouldShowPasswordText? 'text' : 'password'"
                        class="form-control register-field-item"
                        placeholder="{{ langx['REGISTER_PASSWORD_PLACEHOLDER'] }}"
                        formControlName="password"
                        [class.invalid]="isFieldInvalid('password')"
                        (focus)="onPasswordFocus()"
                        (blur)="onPasswordBlur()"
                        [appAutofocusOnEmit]="passwordStrengthDisplayToggle$"
                        #passwordInputField
                    />
                    <span class="show-password-icon-wrapper">
                        <span class="material-symbols-outlined" (click)="onToggleShowPasswordType()">
                            @if(shouldShowPasswordText){
                                <img class="logo icon-password-visibility {{ passwordControl.value?'':'opaque' }}" src="{{ mediaServer }}/zeta/common/icon_password-visible.png" alt="">
                            } @else {
                                <img class="logo icon-password-visibility {{ passwordControl.value?'':'opaque' }}" src="{{ mediaServer }}/zeta/common/icon_password-hidden.png" alt="">
                            }
                        </span>
                    </span>
                    <app-password-strength [show]="shouldShowPasswordStrength" [password]="registrationFormControl['password'].value"></app-password-strength>
                    
                    @if (!shouldShowPasswordStrength){
                        @if (isFieldEmpty('password')) {
                            <p class="register-field-error text-smaller"><span class="material-symbols-outlined">error</span> {{ langx['REGISTER_PASSWORD_EMPTY_FIELD'] }}</p>
                        } @else if (isFieldInvalid('password')) {
                            <p class="register-field-error text-smaller"><span class="material-symbols-outlined">error</span> {{ langx['REGISTER_PASSWORD_FORMAT_INVALID'] }}</p>
                        }
                    }
    
                </div>
    
                <div class="form-group register-flex-item">
                    <input
                        [type]="shouldShowConfirmPasswordText$.value? 'text' : 'password'"
                        class="form-control register-field-item"
                        placeholder="{{ langx['REGISTER_CONFIRM_PASSWORD_PLACEHOLDER'] }}"
                        formControlName="confirmPassword"
                        [class.invalid]="isFieldInvalid('confirmPassword')"
                        [appAutofocusOnEmit]="shouldShowConfirmPasswordText$"
                    />
                    <span class="show-password-icon-wrapper">
                        <span class="material-symbols-outlined" (click)="onToggleShowConfirmPasswordType()">
                            @if(shouldShowConfirmPasswordText$.value){
                                <img class="logo icon-password-visibility {{ confirmPasswordControl.value?'':'opaque' }}" src="{{ mediaServer }}/zeta/common/icon_password-visible.png" alt="">
                            } @else {
                                <img class="logo icon-password-visibility {{ confirmPasswordControl.value?'':'opaque' }}" src="{{ mediaServer }}/zeta/common/icon_password-hidden.png" alt="">
                            }
                        </span>
                    </span>
                    
                    @if(isFieldEmpty('confirmPassword')) {
                        <p class="register-field-error text-smaller"><span class="material-symbols-outlined">error</span> {{ langx['REGISTER_CONFIRM_PASSWORD_EMPTY_FIELD'] }}</p>
                    } @else if (isFieldInvalid('confirmPassword')) {
                        <p class="register-field-error"><span class="material-symbols-outlined">error</span> {{ langx['REGISTER_CONFIRM_PASSWORD_NOT_MATCHED'] }}</p>
                    } @else if(isPasswordMatched()){
                        <p class="register-field-success"><span class="material-symbols-outlined">check_circle</span> {{ langx['REGISTER_CONFIRM_PASSWORD_MATCHED'] }}</p>
                    }
    
                </div>
    
                <div class="form-group register-terms text-very-small">
                    <input
                        type="checkbox"
                        class="form-control"
                        formControlName="terms"
                    />
                    {{ langx['REGISTER_TERMS_TEXT'] }}
                    <a (click)="onToggleShowTerms()" class="link">{{ langx['REGISTER_TERMS_LINK'] }}</a>
                </div>
    
                <button
                    type="submit"
                    class="btn btn-primary btn-block"
                    [disabled]="!isFormValid()"
                >
                    {{ langx['REGISTER_SUBMIT_TEXT'] }}
                </button>
    
                <div class="register-redirection text-center">
                    <small class="text-very-small">{{ langx['REGISTER_LOGIN_TEXT'] }}</small>
                    <a [routerLink]="['../login']" class="link text-very-small">
                        {{ langx['REGISTER_LOGIN_LINK'] }}
                    </a>
                </div>
            </form>
        </div>
    
        <app-cs-chat-icon></app-cs-chat-icon>
    </div>
}
