<div class="login-wrapper">
    <div class="login-form-wrapper">
        <h1 class="login-form-title text header text-center">{{ langx['LOGIN_TITLE'] }}</h1>
        <p class="login-form-description text-small text-center">{{ langx['LOGIN_DESCRIPTION'] }}</p>

        <form [formGroup]="loginForm" class="login-form login-flex-container text-small"
            (ngSubmit)="onLoginSubmitButtonClick()">

            <div class="form-group login-flex-item">
                <input type="text" class="form-control login-field-item" placeholder="{{ langx['LOGIN_NAME_PLACEHOLDER'] }}"
                    formControlName="loginName" (blur)="onLoginNameBlur()" (keyup)="onPasswordOrLoginNameOnkeyUp()"
                    #loginNameInputField />
                <app-clear-input-icon [targetInputField]="loginNameInputField" (clearInput)="onClearInput()" />
                @if(loginForm.get('loginName')?.invalid && isValidLoginName) {
                    <p class="login-field-error text-small"><span class="material-symbols-outlined">error</span> 
                        {{ langx['LOGIN_USERNAME_CANNOT_BE_EMPTY'] }}</p>
                    }
            </div>

            <div class="form-group login-flex-item">
                <input [type]="showPasswordText? 'text' : 'password'" class="form-control login-field-item"
                    placeholder="{{ langx['LOGIN_PASSWORD_PLACEHOLDER'] }}" formControlName="password"
                    (blur)="onPasswordBlur()" (keyup)="onPasswordOrLoginNameOnkeyUp()"/>
                <span class="show-password-icon-wrapper">
                    <span class="material-symbols-outlined" (click)="onToggleShowPassword()">
                        @if(showPasswordText) {
                            <img class="logo icon-password-visibility {{ passwordControl.value?'':'opaque' }}" src="{{ mediaServer }}/zeta/common/icon_password-visible.png" alt="">
                        } @else {
                            <img class="logo icon-password-visibility {{ passwordControl.value?'':'opaque' }}" src="{{ mediaServer }}/zeta/common/icon_password-hidden.png" alt="">
                        }
                    </span>
                </span>

                @if(loginForm.get('password')?.invalid && isValidPassword) {
                    <p class="login-field-error text-small"><span class="material-symbols-outlined">error</span> 
                        {{ langx['LOGIN_PASSWORD_CANNOT_BE_EMPTY'] }}</p>
                    }

                @else if(invalidCredentials) {
                    <p class="login-field-error credentials-error text-small"><span class="material-symbols-outlined">error</span> 
                        {{ invalidCredentialsErrorMsg }}</p>
                    }
            </div>

            <div class="form-group login-remember-container text-very-small">
                <div class="form-control login-remember-me-checkbox" (click)="onToggleRememberMe()">
                    <input type="checkbox" [checked]="isRememberMe">
                    <span class="login-remember-me-text">{{ langx['LOGIN_REMEMBER_TEXT'] }}</span>
                </div>
                <a routerLink="../forgot-password"
                    class="login-forgot-password-link">{{ langx['LOGIN_FORGOT_PASSWORD_LINK'] }}</a>
            </div>

            <button type="submit" class="btn btn-primary btn-block login-btn" [disabled]="!loginForm.valid">
                {{ langx['LOGIN_SUBMIT_TEXT'] }}
            </button>

            <div class="login-register-with-container text text-very-small text-center">
                <div class="login-register-with-text">
                    <span class="login-register-with-line"></span>
                    <span>{{ langx['LOGIN_REGISTER_WITH_TEXT'] }}</span>
                    <span class="login-register-with-line"></span>
                </div>

                <div class="logo-wrapper" routerLink="../register/with-wellbet">
                    <img class="logo" src="{{ mediaServer }}/zeta/user/register/icon_wellbet.png" alt="">
                </div>
            </div>

            <div class="login-redirection text-center">
                <span class="text-very-small">{{ langx['LOGIN_DONT_HAVE_ACCOUNT_YET'] }}</span>
                <a routerLink="../register" class="link text-very-small">
                    {{ langx['LOGIN_REGISTER_LINK'] }}
                </a>
            </div>
        </form>
    </div>

    <app-cs-chat-icon></app-cs-chat-icon>
</div>