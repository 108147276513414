import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from '../../shared/service/common/common.service';
import { UserService } from '../../shared/service/user/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent implements OnDestroy {

  shouldShowBanner: boolean = true;

  mediaServer: string = '';

  destroy$: Subject<void> = new Subject(); 

  constructor(
    private commonService: CommonService, 
    private userService: UserService
  ) {
    this.commonService.mediaServer$.subscribe(mediaServer => this.mediaServer = mediaServer);

    this.userService.bannerViewToggle$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(bannerViewToggle => {
      this.shouldShowBanner = bannerViewToggle;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
