import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ApiError } from '../../../shared/models/http.model';
import { LocalizedMessages } from '../../../shared/models/i18n.model';
import { LoginDTO } from '../../../shared/models/login.model';
import { UserProfile } from '../../../shared/models/user.model';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { CommonService } from '../../../shared/service/common/common.service';
import { CookieService } from '../../../shared/service/cookie/cookie.service';
import { ModalService } from '../../../shared/service/modal/modal.service';
import { PuzzleCaptchaService } from '../../../shared/service/puzzle-captcha/puzzle-captcha.service';
import { UserService } from '../../../shared/service/user/user.service';
import { langxObj } from './login_lang';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnDestroy, OnInit {

  destroy$ = new Subject<void>();
  currentLanguage: string = '';
  langx: LocalizedMessages = {};
  showPasswordText: boolean = false;
  loginForm: FormGroup;
  mediaServer: string = '';
  invalidCredentials: boolean = false;
  invalidCredentialsErrorMsg: string = '';
  isRememberMe: boolean = false;
  verificationStub: string = '';
  isValidLoginName: boolean = false;
  isValidPassword: boolean = false;
  passwordControl!: AbstractControl;


  constructor(
    private commonService: CommonService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private puzzleCaptchaService: PuzzleCaptchaService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private authService: AuthService
  ) {
    const loginName = this.cookieService.getRememberedLoginName();
    this.isRememberMe = loginName ? true : false;
    this.loginForm = this.formBuilder.group({
      loginName: [loginName, [Validators.required, this.noWhiteSpaceValidator]],
      password: ['', [Validators.required, this.noWhiteSpaceValidator]]
    });

    this.passwordControl = this.loginForm.get('password')!;
   
  }

  

  ngOnInit() {
    this.commonService.mediaServer$.subscribe(mediaServer => {
      this.mediaServer = mediaServer;
    });

    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => {
        this.currentLanguage = currentLanguage;
        this.langx = langxObj[this.currentLanguage];
      });

    this.puzzleCaptchaService.verificationStub$.pipe(takeUntil(this.destroy$)).subscribe(verificationStub => {
      this.verificationStub = verificationStub;
    });
  }

  onLoginSubmitButtonClick() {
    this.login();
  }

  login() {
    this.commonService.showLoading();
    this.invalidCredentials = false;

    const loginDto: LoginDTO = this.loginForm.value;
    loginDto.captchaToken = this.cookieService.getLoginCaptchaToken();
    loginDto.verificationStub = this.verificationStub;

    this.userService.login(loginDto).subscribe(response => {
      if (response.status === 'success') {
        this.puzzleCaptchaService.setVerificationStub('');
        this.authService.saveAccessToken(response.data?.accessToken || '');
        this.authService.saveHiggsJwt(response.data?.higgsJwt || '');

        this.cookieService.setRememberedLoginName(this.isRememberMe? loginDto.loginName: '');
        this.cookieService.setLoginCaptchaToken(response.data?.captchaToken ?? '');

        this.userService.setCurrentUser(response.data as UserProfile);

        this.userService.syncPlayerInfo();

        localStorage.setItem('username-credential', loginDto.loginName ?? '');

        this.router.navigate(['../../home'], { relativeTo: this.route });
        this.modalService.showConfirmation(this.langx['LOGIN_SUCCESS_MESSAGE'],
          this.langx['LOGIN_SUCCESS_GOTO_WB'], this.langx['LOGIN_SUCCESS_GOTO_SKIP']).subscribe((gotoWB) => {
          if (gotoWB) {
            this.redirectToWellBet();
          }
        });

      } else {
        this.resolveErrorResponse(response.errors!);
      }

      this.commonService.hideLoading();
    });
  }

  showPuzzleCaptcha() {
    this.puzzleCaptchaService.showPuzzleCaptcha().pipe(takeUntil(this.destroy$))
      .subscribe(puzzleCaptchaEvent => {
        if (puzzleCaptchaEvent.type == 'onSuccess') {
          this.verificationStub = puzzleCaptchaEvent.verificationStub || '';
          this.login();
        }
      });
  }

  onToggleShowPassword() {
    this.showPasswordText = !this.showPasswordText;
  }

  noWhiteSpaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }

  onToggleRememberMe() {
    this.isRememberMe = !this.isRememberMe;
  }

  resolveErrorResponse(errors: ApiError[]) {
    const errorCodes = errors.map((error: ApiError) => error.code).join(',');
    const errorMessages = errors.map((error: ApiError) => error.message).join(',');

    if (/^error.login.invalid-credentials/.test(errorCodes)) {
      this.invalidCredentials = true;
      this.invalidCredentialsErrorMsg = errorMessages;
    } else if (/^error.login.captcha-token.*/.test(errorCodes)
      || (/^error.puzzle-captcha.verification-stub.expired/.test(errorCodes))) {
      this.showPuzzleCaptcha();
    } else {
      this.modalService.showError(errorMessages);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onLoginNameBlur() {
    setTimeout(() => {
      this.isValidLoginName = true;
      const loginNameControl = this.loginForm.get('loginName');
      if (loginNameControl?.pristine && loginNameControl?.value.trim()) {
        this.isValidLoginName = false;
      }
    }, 10);
  }
  
  onClearInput() {
    this.loginForm.get('loginName')?.reset();
    this.loginForm.get('loginName')?.updateValueAndValidity();   
  }

  onPasswordBlur() {
    setTimeout(() => {
      this.isValidPassword = true;
      const loginNameControl = this.loginForm.get('password');
      if (loginNameControl?.pristine && loginNameControl?.value.trim()) {
        this.isValidPassword = false;
      }
    }, 10);
  }
 
  onPasswordOrLoginNameOnkeyUp(){
    this.invalidCredentials = false;
  }

  redirectToWellBet(): void {
    this.commonService.getWellBetUrl().subscribe((responseUrl) => {
      window.location.href = responseUrl.data.wellbetUrl;
    });
  }
}
